import React, { useRef } from "react";
import Container from "../../Components/Container";
import Wrapper from "../../Components/Wrapper";

// ASSETS
// Banner
import WorkBanner from "../../assets/Work/work_1.png";

// Vantagens
import ImageVantagem1 from "../../assets/Work/vantagem_1.png";
import ImageVantagem2 from "../../assets/Work/vantagem_2.png";
import ImageVantagem3 from "../../assets/Work/vantagem_3.png";

// Como trabalhar
import Folha from "../../assets/SVG/folha.svg";
import Telefone from "../../assets/SVG/telefone.svg";
import Touch from "../../assets/SVG/touch.svg";
// Styles
import { Main } from "./styles";

// Form
import Form from "../../Components/Form/Form";
import FormInput from "../../Components/Form/Input";
import FormTextarea from "../../Components/Form/Textarea";
import { Label } from "../../Components/Form/File/styles";
import Button from "../../Components/Button";
import Title from "../../Components/Title";
import api from "../../services/api";
import Loading from "../../Components/Loading";
import Modal from "../../Components/Modal";

// Input Validate
import { equipeValidate } from "../../schema/form/formEquipeValidate";
import TextAlert from "../../Components/TextAlert";
import { useHistory } from "react-router";

function EquipeRehab() {
  const [nome, setNome] = React.useState("");
  const [telefone, setTelefone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [mensagem, setMensagem] = React.useState("");
  const [file, setFile] = React.useState(null);

  const [loadingActive, setLoadingActive] = React.useState(false);
  const [loadingLabel, setLoadingLabel] = React.useState("");

  const [invalidDatesAlertActive, setInvalidDatesAlertActive] =
    React.useState(false);
  const [invalidDatesAlertLabel, setInvalidDatesAlertLabel] =
    React.useState("");

  async function handleSubmit() {
    setLoadingLabel("Validando dados");
    if (nome && telefone) {
      equipeValidate.isValid({ nome, telefone }).then(async (isValid) => {
        if (isValid) {
          setLoadingActive(true);
          setLoadingLabel("Processando dados");

          setLoadingLabel("Abrindo gerenciador de email...");

          window.location = `mailto:contato@rehabfisioterapia.com?subject=[Candidato] - ${nome}.&body=${`
              Nome: ${nome}%0D%0A
              Telefone:  ${telefone}%0D%0A
              Via: rehabfisioterapia.com
              %0D%0A%0D%0A ---------- %0D%0A OBS: Lembre-se de anexar seu curriculo!
              %0D%0A ---------- %0D%0A%0D%0A`}`;
        } else {
          setInvalidDatesAlertActive(true);
          setInvalidDatesAlertLabel(
            "Dados inválidos! Por favor preencha os dados corretamente"
          );
        }
      });
    } else {
      setInvalidDatesAlertActive(true);
      setInvalidDatesAlertLabel("Preencha todos os dados!");
    }
  }

  React.useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => document.querySelector("#form").scrollIntoView(), 1000);
  }, []);
  return (
    <Container>
      <Loading
        type="MutatingDots"
        label={loadingLabel}
        active={loadingActive}
        setActive={setLoadingActive}
      />
      <TextAlert
        label={invalidDatesAlertLabel}
        active={invalidDatesAlertActive}
        setActive={setInvalidDatesAlertActive}
      />
      <Wrapper>
        <Main class="container-fluid">
          <Title>Trabalhe conosco</Title>
          <img
            className="d-block w-100"
            src={WorkBanner}
            alt="Rehab"
          />
          <div className="row" id="vantagens">
            <h1 id="title">como fazer parte do time?</h1>
            <div className="col-sm">
              <img alt="picture" src={Touch} />
              <p>Faça sua inscrição</p>
            </div>
            <div className="col-sm">
              <img alt="picture" src={Folha} />
              <p>Anexe seu currículo</p>
            </div>
            <div className="col-sm">
              <img alt="picture" src={Telefone} />
              <p>Aguarde nosso contato</p>
            </div>
          </div>
          <div className="row" id="como-trabalhar">
            <h1 id="title">Vantagens de fazer parte do time Rehab</h1>
            <div className="col-sm">
              <img
                alt="picture"
                id="image-como-trabalhar"
                src={ImageVantagem1}
              />
              <p>Defina suas áreas de atendimento</p>
            </div>
            <div className="col-sm">
              <img
                alt="picture"
                id="image-como-trabalhar"
                src={ImageVantagem2}
              />
              <p>Faça seus horários</p>
            </div>
            <div className="col-sm">
              <img
                alt="picture"
                id="image-como-trabalhar"
                src={ImageVantagem3}
              />
              <p>Atenda sua especialidade</p>
            </div>
          </div>
          <Form
            onSubmit={handleSubmit}
            label="Adicione suas informações"
            subLabel="Entraremos em contato em até 24h"
            id="form"
          >
            <FormInput
              value={nome}
              setValue={setNome}
              label={"Nome"}
              placeholder="Seu nome"
            />
            <FormInput
              value={telefone}
              setValue={setTelefone}
              label={"Telefone"}
              placeholder="DDD + Número"
            />

            {/* TODO: Anexo vai sumir, será adicionado no email */}
            {/* Quando clicar em enviar, abrir modal com video explicativo
              ensinando como anexar o curriculo */}

            <button
              type="button"
              style={{ background: "#15a516", width: "100px", height: "40px" }}
              class="btn btn-primary h1 fw-bold b-none float-left ml-20"
              data-toggle="modal"
              data-target="#exampleModal"
            >
              Proximo passo
            </button>
          </Form>
        </Main>
      </Wrapper>
      <Modal
        onClose={handleSubmit}
        title="Anexe seu curriculo ao email."
        closeButtonText="Entendido"
      >
        <div className="">
          No proximo passo, você deve anexar seu curriculo.
        </div>
      </Modal>
    </Container>
  );
}

export default EquipeRehab;
