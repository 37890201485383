import styled from 'styled-components';

export const MainHome = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  h1#title {
    margin-top: 8rem;
    margin-bottom:  4rem;
    font-size: 4rem;
    font-weight: 700;
  }

.description p {
    margin-top: 2rem;
    font-size: 2rem;
  }

  .description img {
    width: 22rem;
  }

  img.image {
    width: 100%;
  }

  .description img#icon {
    
    width: 9rem;
    height: auto;
  }

  button {
    margin: 3rem 0;
  }

  @media screen and (max-width: 992px) {
    .row {
    width: 80%;
  }
  }
`;
