import styled from 'styled-components';

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
`;

export const CardContainer = styled.div`
    .card-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        
    }
    .card-label {
        font-size: 4rem;
        font-weight: 700;
        
    }
    .card-description {
        text-align: center;
    }
`
