import styled from 'styled-components';

export const FormContainer = styled.form`
    width: ${props => props.banner ? '65%': '100%'};
    margin-right: 2rem;
  
`;

export const FormTitle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    margin-top: 5rem;
    margin-bottom: 2rem;
    
    h1#form-label {
        font-size: 3rem;
        font-weight: 700;
    }
`

export const Banner = styled.img`
    width: 35%;
    height: 50rem;
`