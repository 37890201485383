import React from 'react';
import Container from '../../Components/Container';
import Wrapper from '../../Components/Wrapper';
import Title from '../../Components/Title.js';

// Images
// Alt
import ALTRehabLogo from '../../assets/SVG/colorido_completo.svg'

// Sobre
import ImageSobre2 from '../../assets/About/about_2.png'
import ImageSobre3 from '../../assets/About/about_3.png'
import ImageSobre3Mobile from '../../assets/About/about_3_mobile.png'
import ImageSobre1Mobile from '../../assets/About/about_1_mobile.png'


// Como Funciona
import Telefone from '../../assets/SVG/telefone.svg'
import Folha from '../../assets/SVG/folha.svg'
import Casa from '../../assets/SVG/casa.svg'

// Vantagens
import ImageVantagem1 from '../../assets/About/vantagem_1.png'
import ImageVantagem2 from '../../assets/About/vantagem_2.png'
import ImageVantagem3 from '../../assets/About/vantagem_3.png'

// Por que escolher

import ImagePorqueEscolher1 from '../../assets/About/porque_escolher_1.png'
import ImagePorqueEscolher2 from '../../assets/About/porque_escolher_2.png'
import ImagePorqueEscolher3 from '../../assets/About/porque_escolher_3.png'

import { Main } from './styles';

function About() {
  
  React.useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return <Container>
      <Wrapper>
          <Main class="container-fluid ">
            <Title>Sobre</Title>
            <div className="row" id="sobre">
              <img alt="Imagem" src={ImageSobre1Mobile} className="w-100 my-4 " id="about-banner" />
              <h1 id="title" className="text-left" >Sobre a Rehab</h1>
              <p>A Rehab nasceu a partir da percepção de sua idealizadora, que é fisioterapeuta, e sempre atuou no ramo da fisioterapia domiciliar. Ao notar a grande demanda de pacientes que por alguma razão, não podiam se locomover até uma unidade de atendimento, ou tinham dificuldades em encontrar profissionais qualificados, criou a Rehab, visando criar uma solução a essas demandas. Assim também, ela enquanto profissional atuante do ramo, também se deparou com várias dificuldades e limitações, no seu dia-a-dia de trabalho. A proposta da Rehab é trazer soluções práticas para os pacientes, assim como solucionar as limitações encontradas pelos profissionais. O nome Rehab foi inspirado na palavra “Rehabilitare” e também possui tradução do inglês para “reabilitação”. Nosso objetivo é facilitar a intermediação do serviço de fisioterapia, direcionando profissionais qualificados até a casa do paciente que necessita do serviço.</p>
            </div>
            
            <div className="row" id="como-funciona">
              <h1 id="title" >Como funciona a Rehab?</h1>
              <div className="col-sm" id="col">
                <img alt="Imagem" src={Telefone}/>
                <p>Entre em contato conosco</p>
              </div>
              <div className="col-sm" id="col">
                <img alt="Imagem" src={Folha}/>
                <p>Faremos uma triagem</p>
              </div>
              <div className="col-sm" id="col">
                <img alt="Imagem" src={Casa}/>
                <p>Direcionaremos um profissional até seu lar</p>
              </div>
            </div>
            <div className="row" id="fisioterapia-domiciliar">
              <img alt="Imagem" src={ImageSobre2} className="d-block w-100 my-4" />
              <h1 id="title" >Sobre a fisioterapia domiciliar</h1>
              <p>A fisioterapia realizada na casa do paciente, possibilita maior interação do profissional com a família, assim como a identificação de possíveis limitantes do ambiente em que o paciente vive. Essas informações agregam no tratamento de modo que o mesmo pode ser adaptado a essas características, tornando o atendimento mais personalizado e individualizado. O fato de não necessitar se deslocar até um centro de saúde, economiza tempo, permite maior flexibilidade de horários, além de trazer conforto para as pessoas que possuem limitantes crônicos, mobilidade reduzida, ou se sentem inseguras para saíram sozinhas, em casos de não possuírem acompanhantes.</p>
              
              <h1 id="title" >Vantagens da fisioterapia domiciliar</h1>
              <div className="col-sm" id="col">
                <img alt="Imagem" src={ImageVantagem1} />
                <p>Atendimento individualizado</p>
              </div>
              <div className="col-sm" id="col">
                <img alt="Imagem" src={ImageVantagem2} />
                <p>Contato do profissional com a família</p>
              </div>
              <div className="col-sm" id="col">
                <img alt="Imagem" src={ImageVantagem3} />
                <p>Otimização do seu tempo</p>
              </div>
            </div>
            <div className="row" id="porque-escolher">
              <h1 id="title" >Por que escolher a Rehab?</h1>
              <div className="col-sm" id="col">
                <img alt="Imagem" src={ImagePorqueEscolher1} />
                <p>Profissionais qualificados</p>
              </div>
              <div className="col-sm" id="col">
                <img alt="Imagem" src={ImagePorqueEscolher2} />
                <p>Atendimento rápido</p>
              </div>
              <div className="col-sm" id="col">
                <img alt="Imagem" src={ImagePorqueEscolher3} />
                <p>Comprometimento com seu tratamento</p>
              </div>
            </div>
            
            <div className="row" id="quem-sao-os-profissionais">
              <picture className="col-sm-4">
                <source
                  media="(max-width: 575.98px)"
                  srcset={ImageSobre3Mobile}
                  className=" d-block w-100 my-4" />
                <img alt="Imagem" src={ImageSobre3} className="d-block w-100 my-4" />
              </picture>
              <div className="col-sm-8">
              <h1 id="title" className="text-left" >Quem são nossos profissionais?</h1>
              <p>Fisioterapeutas, previamente selecionados e entrevistados, aptos a prestar atendimento para sua necessidade.</p>
              </div>
            </div>
          </Main>
      </Wrapper>
  </Container>;
}

export default About;
