import React from 'react';

import { Input, InputLabel } from './styles';

function FormInput({ value, setValue, label, ...props }) {
  return (
    <InputLabel >
      <span>{label}</span>
    <Input value={value} onChange={({ target }) => setValue(target.value)} {...props}  />
    </InputLabel>
  );
}

export default FormInput;