import React from 'react';

import { SidebarContainer, SidebarLogo, SidebarIcon, Main, SidebarMenu } from './styles';

import LogoColorido from '../../assets/SVG/colorido_completo.svg'
import LogoBranco from '../../assets/SVG/branco_completo.svg'
import SidebarOpenIconWhite from '../../assets/SVG/menu_sidebar_branco.svg'
import SidebarOpenIconGreen from '../../assets/SVG/menu_sidebar_verde.svg'
import SidebarBack from '../../assets/SVG/voltar_sidebar.svg'
import { Link, useLocation } from 'react-router-dom';

function Sidebar() {
    const [active, setActive] = React.useState(false)
    const [switchPage, setSwitchPage] = React.useState('')

    const location = useLocation()
    let page = location.pathname
    
    const pageWithHeader = ["/", "/servicos"]
    const withHeader = pageWithHeader.includes(page)
    

    React.useState(() => {
        setActive(false)
    }, [page])





  return <>
  <SidebarContainer withHeader={withHeader}>
      <Main>
        <Link to="/">
            <SidebarLogo src={withHeader ? LogoBranco : LogoColorido}/>
        </Link>
        <SidebarIcon src={withHeader ? SidebarOpenIconWhite : SidebarOpenIconGreen} onClick={() => setActive(!active)}/>   
    </Main>
  </SidebarContainer>
  <SidebarMenu active={active} >
      <div id="wrapper">
          <ul>
              <li><Link onClick={() => setActive(!active)} to="/">Home</Link></li>
              <li><Link onClick={() => setActive(!active)} to="/servicos">Serviços</Link></li>
              <li><Link onClick={() => setActive(!active)} to="/contato">Contato</Link></li>
              <li><Link onClick={() => setActive(!active)} to="/sobre">Sobre</Link></li>
              <li><Link onClick={() => setActive(!active)} to="/equipe-rehab">Trabalhe conosco</Link></li>
          </ul>
      <img src={SidebarBack} alt="VOLTAR" onClick={() => setActive(!active)} />
      </div>
  </SidebarMenu>
  </>;
}

export default Sidebar;
