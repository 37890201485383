import React from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { NavContainer, Main, Logo } from './styles';

import WhiteLogo from '../../assets/SVG/branco_completo.svg'
import GreenLogo from '../../assets/SVG/colorido_completo.svg'

function Navigation() {

  const location = useLocation()
  let page = location.pathname
  
  const pageWithHeader = ["/", "/servicos"]
  const NavigationRelative = ["/", "/servicos"]
  const ColorLogo = ["/", "/servicos"]

  const withHeader = pageWithHeader.includes(page)
  const Relative = NavigationRelative.includes(page)
  const Color = ColorLogo.includes(page)
  const activeStyle = {
      color: withHeader ? '#15a516' : '#fff',
      background: withHeader ? '#fff' : '#15a516',
      padding: '.5rem',
      borderRadius: '.5rem',
    };

    return  <NavContainer
    withHeader={withHeader}
    relative={Relative}
    id="nav">
    
      <Main>
        <Link to="/">
          <Logo src={Color ? WhiteLogo : GreenLogo} alt="Rehab"/>
        </Link>
        <nav>
          <NavLink activeStyle={activeStyle} exact to="/" end>HOME</NavLink>
          <NavLink activeStyle={activeStyle} exact to="/servicos">SERVIÇOS</NavLink>
          <NavLink activeStyle={activeStyle} exact to="/contato">CONTATO</NavLink>
          <NavLink activeStyle={activeStyle} exact to="/sobre">SOBRE</NavLink>
          <NavLink activeStyle={activeStyle} exact to="/equipe-rehab">TRABALHE CONOSCO</NavLink>
        </nav>
  </Main>
</NavContainer>;
}

export default Navigation;