import styled from 'styled-components';

export const Main = styled.div`
   display: flex;
   flex-direction: column;
   
   h1#title {
    font-size: 4rem;
    font-weight: 700;
    margin: 2rem 0;
    text-align: center;
   }

   #sobre {
      text-align: left;

   img#about-banner{
      display: none
   }

      @media screen and (max-width: 575.98px) {
         flex-direction: column;

         img#about-banner {
            display: block;
         }
         h1#title {
            margin: 2rem 0;
         }
      }
   }

   #como-funciona {


      img {
         width: 9rem;
         height: auto;
         margin-bottom: 2rem;
      }

      @media screen and (max-width: 575.98px) {
         img {
            margin-bottom: .5rem;
         }

         h1 {
            margin: 1rem 0;
         }
      }
   }

   #fisioterapia-domiciliar {
      img {
         width: 100%;
         margin-bottom: 2rem;
      }
      @media screen and (max-width: 575.98px) {
         img {
            width: 55%;
            margin-bottom: .5rem;
         }
      }
   }

   #porque-escolher {
      img {
         width: 100%;
         margin-bottom: 2rem;
      }
      
      @media screen and (max-width: 575.98px) {
         img {
            width: 55%;
            margin-bottom: .5rem;
         }
      }
   }

   #quem-sao-os-profissionais {
      text-align: left;
      @media screen and (max-width: 575.98px) {
         flex-direction: column;
         text-align: center;

         h1 {
            margin: 2rem 0;
         }
      }
   }

   @media screen and (max-width: 575.98px) {
      &, h1#title, p{
            text-align: center;
         }
   }
  
`;
