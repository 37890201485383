import styled from 'styled-components';

// Banner Home
import BannerHome from '../../assets/Header/home_bg.png'
import BannerHomeMobile from '../../assets/Header/home_mobile_bg.png'

// Banner Serviços
import BannerServices from '../../assets/Header/services_bg.png'
import BannerServicesMobile from '../../assets/Header/services_mobile_bg.png'

export const HeaderContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  height: 85vh;

  color: var(--color-text-light);
 
  background-color: var(--color-primary);
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${props => props.home ? BannerHome : BannerServices});

  border-bottom: 3rem solid #dddd;

  /* header {
     
  }
  */

  @media screen and (max-width: 575.98px) {
    background-image: url(${props => props.home ? BannerHomeMobile :  BannerServicesMobile});
  }


`;

export const Showcase = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;

  span#showcase-title {
    font-size: 3rem;
    font-weight: 700;

    width: min(50rem, 90%);

    & > span {
      font-size: 5rem;
    }
  }
  p#showcase-description {
    width: 94%;
    font-size: 2.2rem;
  }

  @media screen and (min-height: 768px) {
    span#showcase-title {
      font-size: 3.4rem;


      & > span {
        font-size: 6rem;
      }
      
    }

    p#showcase-description {
      font-size: 2.6rem;
    }
  }

  @media screen and (min-width: 1335.98px) {
    align-self: center;
    padding-top: 0rem;

    span#showcase-title {
      font-size: 4rem;
      width: 55rem;
    }

    p#showcase-description  {
      width: 500px;
    }
  }

  @media screen and (max-height: 949.98px){

    span#showcase-title {
      font-size: 3rem;
    }

    p#showcase-description  {
      font-size: 2.6rem;
    }
  }

  /* @media screen and (min-height: 895.2px) and (orientation: landscape)  {

  } */
    

  @media screen and (min-width: 1400px) {

    span#showcase-title {
      font-size: 4.6rem;

      & > span {
      font-size: 6rem;
     }

    }
    p#showcase-description {
      
      font-size: 3rem;
    }
  }

  @media screen and (min-width: 1400px) and (max-height: 670px){
    span#showcase-title {
      font-size: 4rem;
    }
  }

  @media screen and (max-height: 760px){
    span#showcase-title {
      font-size: 3rem;

      & > span {
        font-size: 5rem;
      }
      p#showcase-description  {
        font-size: 2.5rem;
      }
    }
  }
`



export const Flow = styled.img`
  display: ${props => props.home ? 'block' : 'none'};
  width: 10rem;
  /* margin-bottom: 5rem; */ // desktop

  @media screen and (max-height: 663px) {
    width: 9rem;
  }

  @media screen and (min-width: 1400px) {
    width: 14rem;
  }

  @media screen and (max-height: 760px){
    width: 10rem;
  }

  @media screen and (min-height: 895.2px){
    width: 11rem;
  }

  @media screen and (min-width: 1335.98px) {
    width: 13rem;
  }

  @media screen and (max-height: 949.98px) and (orientation: landscape) {
    width: 10rem;
  }

`