import styled from "styled-components";

export const LoadingContainer = styled.div`
    width: 100vw;
    height: 100vh;

    display: ${props => props.active ? 'flex' : 'none'};
    align-items: center;
    justify-content: center;

    background: rgba(255, 255, 255, 0.5);

    position: fixed;
    top: 0;
    bottom: 0;
    
`

export const Main = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        width: 100%;
        color: var(--color-text-dark);
        font-weight: 700;
    }
`

