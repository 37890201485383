import styled from 'styled-components';

export const CarouselContainer = styled.div`
  width: 100%;
  height: auto;
  margin-top: ${props => props.home ? '-12rem' : ''};
  z-index: 1;
  .carousel-indicators {
    z-index: 1;
  }

  .carousel-inner{
    border-radius: 1rem;
    }

  @media screen and (max-width: 575.98px) {
    margin-top: ${props => props.home ? '-9rem' : ''};
  }
`;
