import React from 'react';

import { CarouselContainer } from './styles';

function Carousel({ data, home,...props }) {
  return (
      <CarouselContainer id='carouselComponent' {...props} home={home}  className="carousel slide" data-ride="carousel">
          <ol className="carousel-indicators">
              {data.map((_, index) => {
                  if (index === 0) {
                    return <li key={index} data-target="#carouselComponent" data-slide-to={index} className="active"></li>
                  }

                  return <li key={index} data-target="#carouselComponent" data-slide-to={index}></li>
              })}

          </ol>
          <div className="carousel-inner">
              {data.map((picture, index) => {
                
                if (index === 0) {
                    return <div key={index} className="carousel-item active">
                        <img src={picture} alt="carousel image" className="d-block w-100"/>
                    </div>
                }
                return <div key={index} className="carousel-item">
                <img src={picture} alt="carousel picture" className="d-block w-100"/>
                    </div>
              })}

            <a className="carousel-control-prev" href="#carouselComponent" role="button" data-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only"></span>
            </a>
            <a className="carousel-control-next" href="#carouselComponent" role="button" data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only"></span>
            </a>
          </div>

      </CarouselContainer>
  );
}

export default Carousel;