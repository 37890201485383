import React from 'react';

// Styles
import { FooterContainer, Desc, Pages, Contacts, Main, Copy } from './styles';

// Assets
import Logo from '../../assets/SVG/colorido_completo.svg'
import InstagramLogo from '../../assets/SVG/instagram.svg'
import TwitterLogo from '../../assets/SVG/twitter.svg'
import FacebookLogo from '../../assets/SVG/facebook.svg'

// Router
import { Link } from 'react-router-dom';

function Footer() {
  return <>
  <FooterContainer className="footer navbar-fixed-bottom">
    <Main className="row">
      <Desc className="col-md-4" id="footer-col">
        <Link to="/">
          <img alt="" src={Logo} id="logo"/>
        </Link>
        <p>A rehab é a sua opção moderna para serviços de fisioterapia domiciliar. Escolha uma especialidade, envie uma mensagem e nós entraremos em contato em até 24h para que um profissional possa ser selecionado para ir até o seu lar</p>
        <div id="redes-sociais">
          <div id="redes-sociais-wrapper">
            <a target="_blank" href="https://www.instagram.com/rehab.fisio_/" rel="noreferrer"><img alt="Instagram" src={InstagramLogo}/></a>
            <a target="_blank" href="https://twitter.com/rehabfisio" rel="noreferrer"><img alt="Twitter" src={TwitterLogo}/></a>
            <a target="_blank" href="https://www.facebook.com/rehabfisioterapiadomiciliaroficial" rel="noreferrer"><img alt="Fabecook" src={FacebookLogo}/></a>
          </div>
        </div>
      </Desc>
      <Pages className="col-md-4" id="footer-col">
        <div id="pages-wrapper">
          <Link to="/">HOME</Link>
          <Link to="/servicos">SERVIÇOS</Link>
          <Link to="/contato">CONTATO</Link>    
          <Link to="/sobre">SOBRE</Link>    
          <Link to="/equipe-rehab">TRABALHE CONOSCO</Link>    
        </div>
      </Pages>
      <Contacts className="col-md-4" id="footer-col">
        <a
        className='hover:fw-bold'
        data-toggle="tooltip"
        data-placement="right"
        title="Nos envie um email!"
        href="mailto:contato@rehabfisioterapia.com?Subject=Quero%20enviar%20uma%20mensagem.">CONTATO@REHABFISIOTERAPIA.COM.BR</a>
      </Contacts>
    </Main>
  </FooterContainer>
      <Copy>
        <div id="copy-wrapper">
          <span>2020 Copyright: <strong>Rehab</strong> - Fisioterapia domiciliar.</span>
          <div id="developers">
            <a
            data-toggle="tooltip" data-placement="right" title="Envie uma mensagem pelo instagram!"
              href="http://instagram.com/john_dsgnr">
                Projeto: <strong>Jonathan Weslley</strong>
            </a>
            <a
              data-toggle="tooltip" data-placement="right" title="Envie uma mensagem pelo whatsapp!"
              target={'_blank'}
              href="https://api.whatsapp.com/send?phone=5584996465565&text=Ol%C3%A1,%20quero%20fazer%20algumas%20perguntas%20sobre%20sites." rel="noreferrer" >
                Desenvolvimento: <strong>Gustavo Albuquerque</strong>
            </a>
          </div>
        </div>
      </Copy>
      </>
}

export default Footer;