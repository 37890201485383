import React from "react";
import Container from "../../Components/Container";
import Wrapper from "../../Components/Wrapper";
import Header from "../../Components/Header";
import Card from "../../Components/Card";

// Style
import Styles from "./styles.module.css";

// Card Images
import fisioterapia_ortopedica from "../../assets/Servicos/fisioterapia_ortopedica.png";
import fisioterapia_neurologica from "../../assets/Servicos/fisioterapia_neurologica.png";
import fisioterapia_dermato_funcional from "../../assets/Servicos/fisioterapia_dermato_funcional.png";
import fisioterapia_uroginecologica from "../../assets/Servicos/fisioterapia_uroginecologica.png";
import fisioterapia_desportiva from "../../assets/Servicos/fisioterapia_desportiva.png";
import fisioterapia_respiratoria from "../../assets/Servicos/fisioterapia_respiratoria.png";
import terapias_manuais from "../../assets/Servicos/terapias_manuais.png";
import acupuntura from "../../assets/Servicos/acupuntura.png";
import rpg from "../../assets/Servicos/rpg.png";
// Card Content
const data = [
  {
    //
    label: "Fisioterapia ortopédica",
    img: fisioterapia_ortopedica,
    description:
      "A fisioterapia Ortopédica tem como foco a prevenção, e tratamento de doenças e lesões que afetam os ossos, músculos, tendões, ligamentos e articulações do corpo. Tendinites, torções, e fraturas, por exemplo, são alguns dos acometimentos tratados por esse segmento da fisioterapia.",
  },
  {
    //
    label: "Fisioterapia neurofuncional",
    img: fisioterapia_neurologica,
    description:
      'A fisioterapia Neurofuncional tem como foco atuar na prevenção, tratamento, e/ou adaptação, das sequelas resultantes dos danos causados ao sistema nervoso, abrangendo tanto o Sistema Nervoso Central como o Periférico. O acidente vascular encefálico (AVE / AVC), conhecido popularmente por "derrame", é um exemplo de acometimento que pode causar tais danos e sequelas.',
  },
  {
    //
    label: "Fisioterapia dermato funcional",
    img: fisioterapia_dermato_funcional,
    description:
      "A fisioterapia Dermato Funcional é uma especialidade da fisioterapia que atua na prevenção, promoção, e recuperação do sistema tegumentar. Tal sistema é responsável pelo revestimento externo do corpo, formado principalmente pela pele. Um exemplo da atuação do fisioterapeuta dermato funcional, é no pós operatório cirurgias plásticas, reduzindo edema, para otimização do processo cicatricial.",
  },
  {
    //
    label: "Fisioterapia uroginecológica",
    img: fisioterapia_uroginecologica,
    description:
      "A Fisioterapia Rroginecológica é focada na prevenção e/ou tratamento de transtornos relacionados aos músculos do assoalho pélvico. Esses músculos são responsáveis pelo controle da urina e das fezes. Também seguram os órgãos pélvicos como a bexiga, o útero e reto. Incontinência urinaria, é uma disfunção tratada por essa especialidade.",
  },
  {
    //
    label: "Fisioterapia Respiratória",
    img: fisioterapia_respiratoria,
    description:
      "A Fisioterapia Respiratória atua objetivando prevenir, recuperar ou amenizar, disfunções no processo de respiração do ser humano. Isso possibilita melhor funcionalidade e qualidade de vida para as pessoas que sofrem com disfunções respiratórias.",
  },
  {
    label: "Terapias manuais",
    img: terapias_manuais,
    description:
      "Terapias Manuais engloba inúmeras técnicas como: Ventosaterapia; Quiropraxia; Dry Needling (Agulhamento seco); Liberação Miofascial; Crochetagem; Maitland; Mulligan; dentre outras. O objetivo é melhorar a função muscular e articular, proporcionando melhora de dor, postura, amplitude de movimento, além do bem estar geral do indivíduo.",
  },
  {
    label: "Fisioterapia Desportiva",
    img: fisioterapia_desportiva,
    description:
      "A Fisioterapia Esportiva ou Desportiva, é uma especialidade focada em ações específicas para atletas, profissionais ou não, que visa prevenir e tratar lesões.",
  },
  {
    label: "Reeducação postural global (RPG)",
    img: rpg,
    description:
      "RPG é método fisioterapêutico desenvolvido na França, no início da década de 1980, pelo terapeuta Philippe Souchard, cuja finalidade é prevenir e tratar problemas posturais, através de técnicas específicas de alongamento do tecido muscular.",
  },
  {
    label: "Acupuntura",
    img: acupuntura,
    description:
      "Acupuntura é uma técnica milenar que integra a medicina tradicional chinesa. Ela consiste na aplicação de agulhas em pontos específicos do corpo, para tratar doenças e para promover saúde.",
  },
];

function Services() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header
        title="Serviços"
        services
      />
      <Container>
        <Wrapper>
          <Card home data={data} className={Styles.cardStyle} />
        </Wrapper>
      </Container>
    </>
  );
}

export default Services;
