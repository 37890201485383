import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import Navigation from './Components/Navigation'
import Footer from './Components/Footer'

import Contato from './pages/Contato'
import Contratar from './pages/Contratar'
import Home from './pages/Home'
import Services from './pages/Services'
import Sobre from './pages/Sobre'
import EquipeRehab from './pages/EquipeRehab'
import Sidebar from './Components/Sidebar'
import WhatsappIcon from './Components/WhatsappIcon'

export default function Router() {
    return (
        <BrowserRouter>
        <Navigation />
        <Sidebar />
        <WhatsappIcon />
            <Switch onUpdate={() => window.scrollTo(0, 0)}>
                
                <Route exact path="/" component={Home}/>
                <Route exact path="/contato" component={Contato}/>
                <Route exact path="/servicos" component={Services}/>
                <Route exact path="/trabalhe-conosco" component={<h1>Trabalhe Conosco</h1>}/>
                <Route exact path="/contratar" component={Contratar}/>
                <Route exact path="/sobre" component={Sobre}/>
                <Route exact path="/equipe-rehab" component={EquipeRehab}/>
                <Route path="*" element={<h1>Página não encontrada</h1>}/>
            </Switch>
        <Footer />
        </BrowserRouter>
    )
}
