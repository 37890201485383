import styled from 'styled-components';

export const FooterContainer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-background-secondary);
  padding: 2rem;

  @media screen and (min-width: 575.98px) {
    padding: 2rem 0;
  }
`
export const Main = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 575.98px) {
    width: 60%;
    font-size: 1.4rem;
  }

`;

export const Desc = styled.div`
  text-align: center;
  p {
    font-size: 1.4rem;
  }
  img#logo {
    width: 50%;
  }

  div#redes-sociais{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 0;

  div#redes-sociais-wrapper{
    width: 40%;
    display: flex;
    justify-content: space-around;
    }
  }

  @media screen and (min-width: 767.98px) {
    text-align: left;
  }
`
export const Pages = styled.div`
  div#pages-wrapper{
    display: flex;
    flex-direction: column;

    a {
      margin-bottom: 1rem;
    }

    a:hover {
      font-weight: bold;
    }
  }
`
export const Contacts = styled.div`
`

export const Copy = styled.div`
  background: var(--color-copy);
  color: var(--color-text-light);


  div#developers {
    display: flex;
    flex-direction: column;
    font-size: 1.4rem;
  }

  @media screen and (min-width:767.98px){
      div#copy-wrapper {
      flex-direction: row;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
    }
  }

  @media screen and (min-width: 991.98px) {
    div#developers {
      flex-direction: row;
      align-items: center;

      height: 5rem;
    }

    div#developers a:first-child {
      margin-right: 1rem;
    }
  }
`