import React from 'react';

import { Textarea, TextareaLabel } from './styles';

function FormTextarea({label, value, setValue, ...props }) {
  return <TextareaLabel>
    <span>{label}</span>
    <Textarea value={value} onChange={({ target }) => setValue(target.value)} {...props} />
  </TextareaLabel>;
}

export default FormTextarea;