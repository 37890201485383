import styled from 'styled-components';

const Wrapper = styled.div`
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 991.98px) {
        width: 90%
    }

`;

export default Wrapper