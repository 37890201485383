import React from 'react';

import { Button } from './styles';

import VoltarIcon from '../../assets/SVG/voltar.svg'

function Voltar() {
    return <Button>
        <img src={VoltarIcon} alt="<" />Voltar
    </Button>;
}

export default Voltar;