import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    html { 
        --color-background: #fff;
        --color-background-secondary: #f2f2f2;
        --color-primary: #15a516;
        --color-secondary: #da5199;
        --color-details: #DDDDDD;
        --color-copy: #707070;
        --color-headlines: #10bc50;
        --color-text: #404040;
        --color-text-dark: #000000;
        --color-text-light: #fff;
        
        font-size: 62.5%;
        font-family: 'Roboto';
    }
    body { 
        background: var(--color-background);
        color: var(--color-text);
        font-size: 1.6rem;
        text-align: center;
        overflow-x: hidden;
        overflow-y: scroll;
        
        a, a:hover {
            text-decoration: none;
            color: inherit;
        }

        @media screen and (min-width: 1900px) {
            p {
                font-size: 3rem;
            }
        }

    }


`

export default GlobalStyles