import styled from 'styled-components';

export const Main = styled.main`
    width: 100%; 

    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;



    @media screen and (max-width: 575.98px) {
        flex-direction: column-reverse;
        align-items: center;
        picture, picture > * {
            width: 100%;
        }
    }
`;

export const Form = styled.form`
    width: 100%;
    margin-right: 2rem;
    margin-top: 50%;

    @media screen and (max-width: 575.98px) {
        width: 100%;
        margin-right: 0;
    }
`

export const FormTitle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    
    margin-bottom: 2rem;
    
    h1 {
        font-weight: 700;
        font-size: 2.4rem;
    }

`

export const Banner = styled.img`
    height: 50rem;
    margin: 2rem 0;

    @media screen and (max-width: 575.98px) {
        height: auto;
    }
`