import styled from 'styled-components';

export const NavContainer = styled.div`
    width: 100vw;
    height: 9rem;

    display: flex;
    align-items: center;
    justify-content: center;
    
    position: ${props => props.relative && 'relative'}; /* Necessário para funcionar no Safari */
    position: ${props => props.relative ? 'fixed' : 'relative'};
    position: ${props => props.withHeader && ''};
    top: 0;

    font-size: 1.4rem;
    
    background: ${props => props.withHeader &&  'none'};
    background: ${props => props.withHeader ? 'linear-gradient(90deg, rgba(21,165,22,.8) 0%, rgba(21,165,22,.8) 21%, rgba(21,165,22,.8) 100%); ' : 'none'} ;
    z-index: 999;

    a { 
        text-decoration: none;
        margin: .5rem;
        color: ${props => props.relative ? 'var(--color-text-light)' : 'var(--color-primary)'};
    }

    @media screen and (max-width: 575.98px) {
        display: none
    }

    @media screen and (min-width: 1900px) {
        height: 11rem;
    }

`

export const Main = styled.div`
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    nav {
        width: 60%;
        
        font-size: 1.6rem;
        
        justify-content: space-between;
        display: flex;
        align-items: center;
        
        a:hover {
            color: none;
        }


    }

    @media screen and (min-width: 576px) and (max-width: 660px) {
        nav {
            font-size: 1.4rem;
        }
    }

    @media screen and (max-width: 992px) {
        width: 90%;
    }
    
    @media screen and (min-width: 1900px) {
        justify-content: space-between;
        nav {
            font-size: 2.4rem;
        }
    }
`


export const Logo = styled.img`
    width: 20rem;
    height: auto;

    @media screen and (min-width: 1900px) {
        width: 26rem;
    }
`
