import styled from 'styled-components';

export const Label = styled.label`
    min-width: 25%;
    max-width: 17rem;
    height: 3rem;
    overflow: hidden;

    background: var(--color-details);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .5rem;
    
    font-size: 1.4rem;
    font-weight: 700;

    margin-bottom: 2rem;

    &:hover {
        background: var(--color-text);
        color: var(--color-text-light);
    }

input[type=file]{
    display: none
}
  
`;
