import styled from 'styled-components';

const Title = styled.h1`  
    display: none;

    margin: 2rem 0;
    
    font-size: 2rem;
    font-weight: 700;

@media Screen and (max-width: 575.98px) {
    display: flex;
    align-self: flex-start;

}
`;

export default Title