import styled from 'styled-components';

export const FormButton = styled.button`
    width: 14rem;
    height: 5rem;
    
    background: var(--color-primary);
    color: var(--color-text-light);
    
    border-radius: 1rem;
    border: none;
    
    font-weight: 700;
    float: left;
`;
