import styled from 'styled-components';

export const TextAlertContainer = styled.div`
  display: ${props => props.active ? 'flex' : 'none'};
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 5rem;

  position: fixed;
  top: 0;
  z-index: 9999;

  color: var(--color-text);

  background: var(--color-secondary);
`;
