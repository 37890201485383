import React from 'react';

import { TextAlertContainer } from './styles';

function TextAlert({active, setActive, label}) {
  
  React.useEffect(() => {
    setTimeout(() => {setActive(false)}, 4000)
  }, [active])

  return <TextAlertContainer active={active} >
    <strong>{label}</strong>
  </TextAlertContainer>;
}

export default TextAlert;