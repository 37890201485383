import React from 'react';

import { LoadingContainer, Main } from './styles';
import Loader from "react-loader-spinner";

function Loading({label, active, setActive, type}) {

  React.useEffect(() => {
    setTimeout(() => {setActive(false)}, 2000)
  }, [active])

  return <LoadingContainer active={active}>
      <Main>
          <h1>{label}</h1>
          
      <Loader type={type} color="#15a516" secondaryColor="#da5199" height={80} width={80} />
      </Main>
  </LoadingContainer>;
}

export default Loading;