import React from "react";
import Carousel from "../../Components/Carousel";
import Container from "../../Components/Container";
import Header from "../../Components/Header/index.js";
import Wrapper from "../../Components/Wrapper";
import Button from "../../Components/Button";

// Redirect
import { Link } from "react-router-dom";

// Imagens do Carousel
import CarouselPicture1 from "../../assets/Home/fisioterapia_ortopedica.png";
import CarouselPicture2 from "../../assets/Home/fisioterapia_neurologica.png";
import CarouselPicture3 from "../../assets/Home/fisioterapia_uroginecologica.png";
import CarouselPicture4 from "../../assets/Home/fisioterapia_respiratoria.png";
import CarouselPicture5 from "../../assets/Home/terapias_manuais.png";
import CarouselPicture6 from "../../assets/Home/fisioterapia_desportiva.png";
import CarouselPicture7 from "../../assets/Home/fisioterapia_dermato_funcional.png";
import CarouselPicture8 from "../../assets/Home/acupuntura.png";
import CarouselPicture9 from "../../assets/Home/rpg.png";

// Icones
import Telefone from "../../assets/SVG/telefone.svg";
import Folha from "../../assets/SVG/folha.svg";
import Casa from "../../assets/SVG/casa.svg";

// Vantagens
import Vantagem1 from "../../assets/Home/vantagem_1.png";
import Vantagem2 from "../../assets/Home/vantagem_2.png";
import Vantagem3 from "../../assets/Home/vantagem_3.png";

// Motivos
import Motivo1 from "../../assets/Home/motivo_1.png";
import Motivo2 from "../../assets/Home/motivo_2.png";
import Motivo3 from "../../assets/Home/motivo_3.png";

// Banner trabalhe conosco
import WorkUs from "../../assets/Home/trabalhe_conosco.png";

// Styles
import { MainHome } from "./styles";
import TextBox from "../../Components/TextBox";

function Home() {
  const CarouselData = [
    CarouselPicture1,
    CarouselPicture2,
    CarouselPicture3,
    CarouselPicture4,
    CarouselPicture5,
    CarouselPicture6,
    CarouselPicture7,
    CarouselPicture8,
    CarouselPicture9,
  ];

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header
        home
      />
      <Container>
        <Wrapper>
          <Carousel data={CarouselData} home />
          <TextBox text="Escolha uma especialidade e encaminharemos um profissional capacitado até você!" />

          <MainHome>
            <h1 id="title">Como funciona a Rehab?</h1>
            <div className="row">
              <div className="col-md-4 d-flex flex-column text-center">
                <div className="description">
                  <img alt="Rehab" src={Telefone} id="icon" />
                  <p>Entre em contato conosco</p>
                </div>
              </div>
              <div className="col-md-4 d-flex flex-column text-center">
                <div className="description">
                  <img alt="Rehab" src={Folha} id="icon" />
                  <p>Faremos uma triagem</p>
                </div>
              </div>
              <div className="col-md-4 d-flex flex-column text-center">
                <div className="description">
                  <img alt="Rehab" src={Casa} id="icon" />
                  <p>Direcionaremos um profissional até seu lar</p>
                </div>
              </div>
            </div>
            <h1 id="title">Vantagens da fisioterapia domiciliar</h1>
            <div className="row">
              <div className="col-md-4 d-flex flex-column text-center">
                <div className="description">
                  <img className="image" alt="Rehab" src={Vantagem1} />
                  <p>Atendimento no conforto do seu lar</p>
                </div>
              </div>
              <div className="col-md-4 d-flex flex-column text-center">
                <div className="description">
                  <img className="image" alt="Rehab" src={Vantagem2} />
                  <p>Contato do profissional com a família</p>
                </div>
              </div>
              <div className="col-md-4 d-flex flex-column text-center">
                <div className="description">
                  <img className="image" alt="Rehab" src={Vantagem3} />
                  <p>Otimização do seu tempo</p>
                </div>
              </div>
            </div>
            <h1 id="title">Por que escolher a Rehab?</h1>
            <div className="row">
              <div className="col-md-4 d-flex flex-column text-center">
                <div className="description">
                  <img className="image" alt="Rehab" src={Motivo1} />
                  <p>Profissionais qualificados</p>
                </div>
              </div>
              <div className="col-md-4 d-flex flex-column text-center">
                <div className="description">
                  <img className="image" alt="Rehab" src={Motivo2} />
                  <p>Atendimento rápido</p>
                </div>
              </div>
              <div className="col-md-4 d-flex flex-column text-center">
                <div className="description">
                  <img className="image" alt="Rehab" src={Motivo3} />
                  <p>Comprometimento com seu tratamento</p>
                </div>
              </div>
            </div>
            <Link to="sobre">
              <Button label="SAIBA MAIS" />
            </Link>
            <Link to="/equipe-rehab">
              <img src={WorkUs} alt="Rehab" className="w-100 d-block" />
            </Link>
          </MainHome>
        </Wrapper>
      </Container>
    </>
  );
}

export default Home;
