import React from 'react';

import { FormButton } from './styles';

function Button({label, ...props}) {
  return <FormButton {...props}>
    {label }
  </FormButton>;
}

export default Button;