import styled from 'styled-components'

export const Button = styled.button`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    align-self: flex-start;
    margin: 2rem 0;

    width: 9rem;
    height: 3rem;
    
    font-size: 1.8rem;
    
    background: var(--color-details);
    border: none;
    border-radius: .5rem;
    
`