import React from "react";
import { Link } from "react-router-dom";
import Button from "../../Components/Button";

import { CardWrapper, CardContainer } from "./styles";

function Card({ data, home, ...props }) {
  function setLocalStorageContractPage(dataContratar) {
    localStorage.setItem("@rehabfisioterapia/image", dataContratar.img);
    localStorage.setItem("@rehabfisioterapia/label", dataContratar.label);
    localStorage.setItem(
      "@rehabfisioterapia/description",
      dataContratar.description
    );
  }

  return (
    <CardWrapper home={home}>
      {data.map((card, index) => {
        return (
          <CardContainer key={index} {...props}>
            <Link
              to={{
                pathname: "/contratar",
                state: {
                  image: card.img,
                  label: card.label,
                  description: card.description,
                },
              }}
            >
              <img src={card.img} alt={card.label} className="d-block w-100" />
            </Link>
            <div className="card-body">
              <h1 className="card-label">{card.label}</h1>
              <p className="card-description">{card.description}</p>

              <Link
                to={{
                  pathname: "/contratar",
                  state: {
                    image: card.img,
                    label: card.label,
                    description: card.description,
                  },
                }}
              >
                <Button
                  label="CONTRATAR"
                  onClick={() => setLocalStorageContractPage(card)}
                />
              </Link>
            </div>
          </CardContainer>
        );
      })}
    </CardWrapper>
  );
}

export default Card;
