import styled from 'styled-components';

export const TextareaLabel = styled.label`
	display: flex;
	
	flex-direction: column;
	margin-bottom: 2rem;

	span { 
		font-family: 'Roboto';
		font-weight: 700;
		margin-bottom: 2rem;
		text-align: left;
	}
	
`

export const Textarea = styled.textarea`
  	background: var(--color-details);
	
	border: none;
	border-radius: .5rem;
	padding: .5rem;
	padding-left: 1rem;
	
	width: 100%;

	@media screen and (max-width: 575.98px) {
		width: 100%;
	}
`;
