import React from "react";
import Container from "../../Components/Container";
import Wrapper from "../../Components/Wrapper";

import FormInput from "../../Components/Form/Input";
import FormTextarea from "../../Components/Form/Textarea";
import Title from "../../Components/Title";

import { Main, Banner, FormTitle } from "./styles";
import { Label } from "../../Components/Form/File/styles";

import Button from "../../Components/Button";
import api from "../../services/api";
import { contatoValidate } from "../../schema/form/formContatoValidacao";
import TextAlert from "../../Components/TextAlert";
import Loading from "../../Components/Loading";

// Image
import BannerImage from "../../assets/Contato/contact_1.png";
import BannerImageMobile from "../../assets/Contato/contact_1_mobile.png";
import { useHistory } from "react-router";

import Form from "../../Components/Form/Form";
import Modal from "../../Components/Modal";
// Loading

function Contato() {
  const [nome, setNome] = React.useState("");
  const [telefone, setTelefone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [assunto, setAssunto] = React.useState("");
  const [mensagem, setMensagem] = React.useState("");
  const [file, setFile] = React.useState(null);

  const [loadingActive, setLoadingActive] = React.useState(false);
  const [loadingLabel, setLoadingLabel] = React.useState("");

  const [invalidDatesAlertActive, setInvalidDatesAlertActive] =
    React.useState(false);
  const [invalidDatesAlertLabel, setInvalidDatesAlertLabel] =
    React.useState("");

  const history = useHistory();

  async function handleSubmit() {
    setLoadingLabel("Validando dados");
    if (nome && telefone) {
      contatoValidate.isValid({ nome, telefone }).then(async (isValid) => {
        if (isValid) {
          setLoadingActive(true);
          setLoadingLabel("Processando dados");

          setLoadingLabel("Abrindo gerenciador de email...");

          window.location = `
                  mailto:contato@rehabfisioterapia.com?subject=[Contato] - ${nome}.
                  &body=${`
                    Nome: ${nome}%0D%0A
                    Telefone:  ${telefone}%0D%0A
                    Via: rehabfisioterapia.com`}`;
        } else {
          setInvalidDatesAlertActive(true);
          setInvalidDatesAlertLabel(
            "Dados inválidos! Por favor preencha os dados corretamente"
          );
        }
      });
    } else {
      setInvalidDatesAlertActive(true);
      setInvalidDatesAlertLabel("Preencha todos os dados!");
    }
  }

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <Loading
        type="MutatingDots"
        label={loadingLabel}
        active={loadingActive}
        setActive={setLoadingActive}
      />
      <TextAlert
        label={invalidDatesAlertLabel}
        active={invalidDatesAlertActive}
        setActive={setInvalidDatesAlertActive}
      />
      <Wrapper>
        <Title>Contato</Title>
        <Main>
          <Form
            onSubmit={handleSubmit}
            label="Envie sua mensagem"
            subLabel="e entraremos em contato em até 24h."
            id="form"
          >
            <FormInput
              value={nome}
              setValue={setNome}
              label={"Nome"}
              placeholder="Seu nome"
            />
            <FormInput
              value={telefone}
              setValue={setTelefone}
              label={"Telefone"}
              placeholder="DDD + Número"
            />

            {/* TODO: Anexo vai sumir, será adicionado no email */}
            {/* Quando clicar em enviar, abrir modal com video explicativo
              ensinando como anexar o curriculo */}

            <button
              type="button"
              style={{ background: "#15a516", width: "100px", height: "40px" }}
              class="btn btn-primary h1 fw-bold b-none float-left ml-20"
              data-toggle="modal"
              data-target="#exampleModal"
              onClick={handleSubmit}
            >
              Proximo passo
            </button>
          </Form>
          <picture>
            <source media="(max-width: 575.98px)" srcset={BannerImageMobile} />
            <Banner src={BannerImage} />
          </picture>
        </Main>
      </Wrapper>
    </Container>
  );
}

export default Contato;
