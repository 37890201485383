import React from "react";

function TextBox({text}) {
  return (
    <div
      className="jumbotron jumbotron-fluid w-100 w-20 rounded-5"
      style={{ marginBottom: 0, marginTop: "5rem", backgroundColor: "#15a516" }}
    >
      <div className="container">
        <h1 className="font-weight-bold text-white">
          {text}
        </h1>
      </div>
    </div>
  );
}

export default TextBox;
