import styled from 'styled-components';

export const ContractContainer = styled.div`
  width: 100%;
  text-align: left;

  h1 {
    font-weight: 700;
    font-size: 4rem;
    margin: 2rem 0;
  }
`;
