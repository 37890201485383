import styled from 'styled-components';

export const SidebarContainer = styled.div`
  width: 100vw;
  height: 5rem;
  
  position: fixed;
  top: 0;
  display: none;
  align-items: center;
  justify-content: center;

  background: ${props => props.withHeader &&  'none'};
  background: ${props => props.withHeader ? 'linear-gradient(90deg, rgba(21,165,22,.8) 0%, rgba(21,165,22,.8) 21%, rgba(21,165,22,.8) 100%); ' : 'var(--color-background)'} ;
  z-index: 999;

  #root {
    overflow: ${props => props.active && 'hidden'}
  }



  @media Screen and (max-width: 575.98px) {
    display: flex;
    }
`;

export const Main = styled.div`
    width: 90%;
    

    display: flex;
    align-items: center;
    justify-content: space-between;
    
`
    
export const SidebarLogo = styled.img`
  width: auto;
  height: 3rem;
`

export const SidebarIcon = styled.img`
  width: auto;
  height: 3rem;
`

export const SidebarMenu = styled.div`
    display: none;
    align-items: flex-end;
    justify-content: flex-end;
    
    padding-bottom: 5rem;
    
    position: fixed;
    top: 0;
    right: 0;
    
    height: 100%;  
    background: var(--color-primary);
    transition: width .5s;

    width: 0;
    width: ${props => props.active && '70vw'};

    z-index: 999;
    

    #wrapper {
      text-align: right;
      margin-right: 2rem;

        display: ${props => props.active ? 'block' : 'none'};
      

      ul {
        list-style: none;
        font-size: 2.4rem;
        font-weight: 700;

        margin-bottom: 1rem;

        li {
          margin-bottom: 1rem;
          
        } 
        li a {
          color: var(--color-text-light);
        }      
      }
    }

    @media Screen and (max-width: 575.98px) {
    display: flex;
    }
    
`
