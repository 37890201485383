import React from 'react';

import { WhatsappIconContainer } from './styles';

import ImageWhatsappIcon from '../../assets/SVG/whatsapp.svg'
function WhatsappIcon() {
  return <WhatsappIconContainer>
      <a
      target="_blank"
      data-toggle="tooltip"
      data-placement="right"
      title="+55 71 98203-6552"
      href="https://api.whatsapp.com/send?phone=5571982036552&text=Ol%C3%A1%2C%20vim%20pelo%20site%20%F0%9F%98%83"
      >
        <img src={ImageWhatsappIcon} alt="Fale conosco pelo whatsapp clicando aqui"/>
    </a>
  </WhatsappIconContainer>;
}

export default WhatsappIcon;