import styled from 'styled-components';

export const InputLabel = styled.label`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 2rem;

	span { 
		font-family: 'Roboto';
		font-weight: 700;
	}

	@media screen and (max-width: 575.98px) {
		flex-direction: column;
		text-align: left;

		span {
			margin-bottom: 1rem;
		}
	}
`

export const Input = styled.input`
	width: 80%;
	background: var(--color-details);
	
	border: none;
	border-radius: .5rem;
	padding: .5rem;
	padding-left: 1rem;

	@media screen and (max-width: 575.98px) {
		width: 100%
	}

`;
