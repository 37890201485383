import React from "react";

import { HeaderContainer, Showcase, Flow } from "./styles";
import Logo from "../../assets/SVG/logo.svg";

function Header({ title = undefined, home, services }) {
  return (
    <>
    <HeaderContainer id="header" home={home} services={services}>
      <Showcase home={home} services={services}>
        <Flow src={Logo} home={home} />
        {title ? (
          <span id="showcase-title">{title}</span>
        ) : (
          <span id="showcase-title">
            <span>Rehab</span> <br />O cuidado que você precisa <br/> no conforto do seu lar!
          </span>
        )}
      </Showcase>
    </HeaderContainer>
      </>
  );
}

export default Header;
