import React from "react";
import { Link, useHistory } from "react-router-dom";

import Voltar from "../../Components/Voltar";

// Input
import FormInput from "../../Components/Form/Input";
import FormTextarea from "../../Components/Form/Textarea";

// CSS
import Container from "../../Components/Container";
import Wrapper from "../../Components/Wrapper";
import { ContractContainer } from "./styles";
import Form from "../../Components/Form/Form";
import Button from "../../Components/Button";
import { Label } from "../../Components/Form/File/styles";
import { contratoValidate } from "../../schema/form/formContratoValidação";
import Loading from "../../Components/Loading";
import api from "../../services/api";
import TextAlert from "../../Components/TextAlert";
import Modal from "../../Components/Modal";

function Contratar({ location }) {
  const [nome, setNome] = React.useState("");
  const [telefone, setTelefone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [mensagem, setMensagem] = React.useState("");
  const [file, setFile] = React.useState(null);

  const [loadingActive, setLoadingActive] = React.useState(false);
  const [loadingLabel, setLoadingLabel] = React.useState("");

  const [invalidDatesAlertActive, setInvalidDatesAlertActive] =
    React.useState(false);
  const [invalidDatesAlertLabel, setInvalidDatesAlertLabel] =
    React.useState("");

  const history = useHistory();

  const image = localStorage.getItem("@rehabfisioterapia/image");
  const label = localStorage.getItem("@rehabfisioterapia/label");
  const description = localStorage.getItem("@rehabfisioterapia/description");

  const allOk = image && label && description;
  async function handleSubmit() {
    setLoadingLabel("Validando dados");
    if (nome && telefone) {
      contratoValidate.isValid({ nome, telefone }).then(async (isValid) => {
        if (isValid) {
          setLoadingActive(true);
          setLoadingLabel("Processando dados");

          setLoadingLabel("Abrindo gerenciador de email...");

          window.location = `mailto:contato@contato@rehabfisioterapia.com?subject=[Contrato] - ${nome}.&body=${`
              Nome: ${nome}%0D%0A
              Telefone:  ${telefone}%0D%0A`}
              Via: rehabfisioterapia.com`;
        } else {
          setInvalidDatesAlertActive(true);
          setInvalidDatesAlertLabel(
            "Dados inválidos! Por favor preencha os dados corretamente"
          );
        }
      });
    } else {
      setInvalidDatesAlertActive(true);
      setInvalidDatesAlertLabel("Preencha todos os dados!");
    }
  }

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return allOk ? (
    <Container>
      <Loading
        type="MutatingDots"
        label={loadingLabel}
        active={loadingActive}
        setActive={setLoadingActive}
      />
      <TextAlert
        label={invalidDatesAlertLabel}
        active={invalidDatesAlertActive}
        setActive={setInvalidDatesAlertActive}
      />
      <Wrapper>
        <Link to="/servicos" className="w-100">
          <Voltar />
        </Link>
        <ContractContainer>
          <img src={image} alt="Banner" className="d-block w-100" />
          <h1>{label}</h1>
          <p>{description}</p>
        </ContractContainer>
        <Form
          label="Adicione suas informações"
          subLabel="Entraremos em contato em até 24h"
          id="form"
        >
          <FormInput
            value={nome}
            setValue={setNome}
            label={"Nome"}
            placeholder="Seu nome"
          />
          <FormInput
            value={telefone}
            setValue={setTelefone}
            label={"Telefone"}
            placeholder="DDD + Número"
          />

          <button
            type="button"
            style={{ background: "#15a516", width: "100px", height: "40px" }}
            class="btn btn-primary h1 fw-bold b-none float-left ml-20"
            data-toggle="modal"
            data-target="#exampleModal"
            onClick={handleSubmit}
          >
            Proximo passo
          </button>
        </Form>
      </Wrapper>
    </Container>
  ) : (
    history.push("/servicos")
  );
}

export default Contratar;
