import styled from 'styled-components';

export const Main = styled.div`
    h1 {
      font-size: 5rem;
      font-weight: 700;
      margin: 2rem 0;
   }

   #vantagens {
       img {
        width: 9rem;
        margin-bottom: 1rem;
       }
   }

   #como-trabalhar {

      #image-como-trabalhar {
         width: 100%
      }
       @media screen and (max-width: 575.98px) {
         #image-como-trabalhar {
            width: 70%;
            margin-bottom: .5rem;
         }
      }
   }

   @media screen and ( max-width: 575.98px ) {
      h1 {
            font-size: 2rem;
         }
   }
`;
