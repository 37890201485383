import React from 'react';

import { FormContainer, FormTitle } from './styles';

function Form({label, subLabel, children, ref, ...props}) {
  return <FormContainer {...props} ref={ref}>
      <FormTitle>
            <h1 id="form-label">{label}</h1>
            {subLabel}
          </FormTitle>
            {children}
  </FormContainer>;
}

export default Form;